.App {
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif;
  background: linear-gradient(135deg, #1e1e1e 0%, #282c34 100%);
  color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  text-align: center;
}

button {
  font-size: 1rem;
  padding: 10px 20px;
  margin: 20px;
  cursor: pointer;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}

input {
  font-size: 1rem;
  padding: 10px;
  margin: 10px;
  border: 2px solid #61dafb;
  border-radius: 5px;
}

.title-image {
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.title-image:hover {
  transform: scale(1.1);
}

.celebration {
  color: #4caf50;
  animation: celebration-animation 2s ease-in-out;
}

@keyframes celebration-animation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgba(20, 20, 20, 0.9); /* Darker background with transparency */
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #fff; /* Brighter text color */
}

.label {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}

.input-field {
  width: calc(100% - 4rem);
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
